import React, { useEffect, useState } from "react";
import "./nav.css";
import logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../comp-utils/CompUtils";
import menu from "../../assets/images/menu-bar.png";

const Navbar = () => {
  const pathname = useLocation().pathname;

  const [active, setActive] = useState(pathname);
  const [showMenu, setShowMenu] = useState(false);

  const clickMenu = () => {
    setShowMenu(!showMenu);
  };

  const innerWidth = window.innerWidth;
  

  const navLinks = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About us",
      link: "/aboutus",
    },
    {
      name: "Products",
      link: "/products",
    },
    {
      name: "Contact us",
      link: "/contactus",
    },
  ];
  const navigate = useNavigate();

  const goToProduct = () => {
    navigate("/products")
  }

  useEffect(() => {
    setActive(pathname);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <div className="nav">
        <div className="nav-logo">
         <Link to={"/"} style={{textDecoration: "none"}}><img src={logo} alt="logo" /></Link> 
        </div>
        <div className="nav-links">
          {navLinks?.map(({ name, link }, idx) => {
            return (
              <ul key={idx}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <li className={active === link ? "active-link" : ""}>
                    {name}
                  </li>
                </Link>
              </ul>
            );
          })}
        </div>
        <div className="nav-btn">
          <Button btnClass={"btn"} btnText={"Go to games"} clickBtn={goToProduct} />
          <div className="tab-menu">
            <img src={menu} alt="menu-bar" onClick={clickMenu} />
          </div>
        </div>
        <div
          className="tab-menu_cont"
          style={{ display: showMenu && innerWidth > "550" ? "flex" : "none" }}
        >
          {navLinks?.map(({ name, link }, idx) => {
            return (
              <ul key={idx}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <li
                    className={active === link ? "active-link" : ""}
                    onClick={clickMenu}
                  >
                    {name}
                  </li>
                </Link>
              </ul>
            );
          })}
        </div>
      </div>
        <div
          className="tab-menu_cont mobile-menu_cont"
          style={{ display: showMenu && innerWidth < "550" ? "flex" : "none" }}
        >
          {navLinks?.map(({ name, link }, idx) => {
            return (
              <ul key={idx}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <li
                    className={active === link ? "active-link" : ""}
                    onClick={clickMenu}
                  >
                    {name}
                  </li>
                </Link>
              </ul>
            );
          })}
        </div>
    </>
  );
};

export default Navbar;
