import React from "react";
import ContactComp from "../components/contact/ContactComp";
import GameCompete from "../components/game-compete/GameCompete";
import { ImageBg } from "../components/comp-utils/CompUtils";
import "../components/game-compete/game-compete.css";
import Faq from "../components/faq/Faq";
import { motion } from "framer-motion";
import splitStringRegex from "../utils/stringRegex";

const Products = () => {
  const headerText1 = "your Playground... your Rules!";

  const header1Chars = splitStringRegex(headerText1);

  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  return (
    <div>
      <ImageBg className={"product-head"}>
        <div className="hero-gradient"></div>
        <motion.h2
          initial="hidden"
          whileInView={"reveal"}
          transition={{ staggerChildren: 0.02 }}
        >
          {header1Chars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.3 }}
              variants={charVariants}
              style={{willChange: "auto"}}
            >
              {char}
            </motion.span>
          ))}
        </motion.h2>
      </ImageBg>
      <div className={"faq-cont"} style={{ marginBottom: "40px" }}>
        <GameCompete product={true} />
      </div>
      <Faq />
      <ContactComp />
    </div>
  );
};

export default Products;
