import React, { useState } from "react";
import "./faq.css";
import plus from "../../assets/images/plus.png";
import minus from "../../assets/images/minus.png";

const Faq = () => {
  const [showAns, setShowAns] = useState(false);
  const [showId, setShowId] = useState();

  const clickShow = (id) => {
    if (showAns) {
      setShowId(id);
    } else {
      setShowAns(!showAns);
      setShowId(id);
    }
    if (showAns && showId === id) {
      setShowAns(false);
    }
  };

  const faqList = [
    {
      id: 1,
      question: "What products does MastersLive Network offer?",
      answer:
        "We offer a wide range of gaming products, including sports betting, online casinos, and physical casinos. Our goal is to provide players with thrilling and immersive experiences, no matter their gaming preferences.",
    },
    {
      id: 2,
      question: "Is MastersLive Network available outside Nigeria?",
      answer:
        "Currently, we are focused on the Nigerian market, but we have ambitious plans to expand into three other African countries by the end of 2027.",
    },
    {
      id: 3,
      question: "What makes MastersLive different from other gaming companies?",
      answer:
        "We combine world-class products, a unique algorithm for setting odds, and a customer-first approach to create unforgettable gaming experiences. Our mission is not only to entertain but to connect with and give back to our local communities.",
    },
    {
      id: 4,
      question: "How do I contact MastersLive customer support?",
      answer:
        "Our customer support team is here to assist you 24/7. You can reach us via email, phone, or live chat on our website.",
    },
  ];

  return (
    <div className={"faq-cont"}>
      <div className="faq">
        <div className="faq-gradient"></div>
        <div className="faq-head">
          <h3>FAQs</h3>
        </div>
        <div className="faq-body">
          {faqList?.map((item, idx) => {
            return (
              <div key={idx} className="each-faq">
                <h3 onClick={() => clickShow(item?.id)}>
                  {item?.question}{" "}
                  <img
                    src={showAns && showId === item?.id ? minus : plus}
                    alt="icon"
                  />
                </h3>
                <p style={{display: showAns && showId === item?.id && "block"}}>{item?.answer}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
