import React from "react";
import "./hero.css";
import circle from "../../assets/images/circle.png";
import x from "../../assets/images/x.png";
import triangle from "../../assets/images/triangle.png";
import box from "../../assets/images/box.png";
import { motion } from "framer-motion";
import splitStringRegex from "../../utils/stringRegex";

const Hero = () => {
  const headerText1 = "Unleash the Game";
  const headerText2 = "Master Within";
  const pText = "Your Ultimate Playground Awaits!";

  const header1Chars = splitStringRegex(headerText1);
  const header2Chars = splitStringRegex(headerText2);
  const pChars = splitStringRegex(pText);

  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  return (
    <div className="hero">
      <div className="hero-head">
        <motion.h1
          initial="hidden"
          whileInView={"reveal"}
          transition={{ staggerChildren: 0.02 }}
        >
          {header1Chars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.8 }}
              variants={charVariants}
              style={{willChange: "auto"}}
            >
              {char}
            </motion.span>
          ))}{" "}
          <br />{" "}
          {header2Chars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.8 }}
              variants={charVariants}
              style={{willChange: "auto"}}
            >
              {char}
            </motion.span>
          ))}
        </motion.h1>
        <motion.p
          initial="hidden"
          whileInView={"reveal"}
          transition={{ staggerChildren: 0.02 }}
        >
          {pChars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.35 }}
              variants={charVariants}
            >
              {char}
            </motion.span>
          ))}
        </motion.p>
      </div>
      <div className="hero-img">
        <img src={circle} alt="O" />
        <img src={x} alt="X" />
        <img src={box} alt="box" />
        <img src={triangle} alt="triangle" />
      </div>
      <div className="hero-gradient"></div>
    </div>
  );
};

export default Hero;
