import React from 'react';
import "./layout.css";
import { Outlet } from 'react-router-dom';
import Navbar from '../navbar/Navbar';

const WebLayout = () => {
  return (
    <div>
        <Navbar />
        <Outlet />
    </div>
  )
}

export default WebLayout