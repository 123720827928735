import React from 'react'
import Hero from '../components/hero/Hero'
import LandAbout from '../components/land-about/LandAbout'
import GameCompete from '../components/game-compete/GameCompete'
import Faq from '../components/faq/Faq'
import ContactComp from '../components/contact/ContactComp'

const Home = () => {
  return (
    <div>
      <Hero />
      <LandAbout />
      <GameCompete />
      <Faq />
      <ContactComp />
    </div>
  )
}

export default Home