import React from 'react';
import "./game-compete.css";
import { Button } from '../comp-utils/CompUtils';

const GameCard = ({pic, title, text, btnText}) => {
  return (
    <div className='game-card'>
      <div className="game-card_border">
      <img src={pic} alt="card-pic" />
      <h3>{title}</h3>
      <p>{text}</p>
      </div>
      <Button btnClass={"btn"}  btnText={btnText} />
    </div>
  )
}

export default GameCard