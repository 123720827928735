import React from 'react'
import ContactComp from '../components/contact/ContactComp'

const Contact = () => {
  return (
    <div>
      <ContactComp />
    </div>
  )
}

export default Contact