import React, { useEffect, useState } from "react";
import "./comp-utils.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Button = ({ btnClass, btnText, btnLink, clickBtn }) => {

  const btnClick = (e) => {
    e.preventDefault();
    if(clickBtn){
      clickBtn();
    }
  }

  return (
    <Link to={btnLink} style={{ textDecoration: "none" }}>
      <motion.button className={btnClass} whileTap={{ scale: 0.85 }} onClick={(e) => btnClick(e)}>
        {btnText}
      </motion.button>
    </Link>
  );
};

export const Input = ({ type, placeholder, className, ...rest }) => {
  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const borderStyle = {
    border: "1px solid #E31617",
  };

  const handleInput = () => {
    setIsTyping(true);
    // Clear any previous timeout to reset the debounce timer
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    // Set a new timeout to set `isTyping` to false after 2 second of inactivity
    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTypingTimeout(timeout);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  useEffect(() => {
    // Cleanup the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div
      className={`input-cont ${className}`}
      style={isTyping ? borderStyle : null}
    >
      <input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={handleInput}
        type={type}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};
export const TextArea = ({ placeholder, className, ...rest }) => {
  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const borderStyle = {
    border: "1px solid #E31617",
  };

  const handleInput = () => {
    setIsTyping(true);
    // Clear any previous timeout to reset the debounce timer
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    // Set a new timeout to set `isTyping` to false after 2 second of inactivity
    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTypingTimeout(timeout);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  useEffect(() => {
    // Cleanup the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div className={`input-cont ${className}`} style={isTyping ? borderStyle : null}>
      <textarea
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={handleInput}
        placeholder={placeholder}
        cols="30"
        rows={"100%"}
        {...rest}
      ></textarea>
    </div>
  );
};

export const SvgCont = ({ children, className }) => {
  return <div className={`svg-comp ${className}`}>{children}</div>;
};

export const ImageBg = ({ children, className }) => {
  return <div className={`img-comp ${className}`}>{children}</div>;
};
