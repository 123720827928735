import React from "react";
import Faq from "../components/faq/Faq";
import ContactComp from "../components/contact/ContactComp";
import { ImageBg } from "../components/comp-utils/CompUtils";
import "../components/land-about/land-about.css";
import "../components/game-compete/game-compete.css";
import AboutComp from "../components/about-comp/AboutComp";

const About = () => {
  return (
    <div>
      <ImageBg className={"about-head"}>
        <div className="hero-gradient about-gradient"></div>
        <h2>
          What is Masterslive <br /> Network?{" "}
        </h2>
      </ImageBg>
      <AboutComp />
      <Faq />
      <ContactComp />
    </div>
  );
};

export default About;
