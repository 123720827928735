import React from "react";
import "./about-comp.css";

const AboutComp = () => {
  return (
    <div className="about-comp">
      <div className="about-cont">
        <h4>About us</h4>
        <p>
          MastersLive Network is a dynamic and innovative gaming company born
          out of a passion for creating thrilling gambling experiences. With a
          range of products that include sports betting, online casinos, and
          physical casino spaces, we are driven by a vision to lead the gaming
          industry in Nigeria and expanding our business across Africa.
          <br />
          <br /> Our journey is built on a foundation of excellence, and we are
          committed to delivering world-class products through exciting,
          innovative channels. We understand that gaming is more than just
          entertainment—it's about building connections, celebrating wins, and
          creating memorable moments. <br />
          <br />
          We believe in empowering our customers and communities by delivering a
          superior gaming experience, combining advanced technology, a winning
          algorithm for setting odds, and unparalleled customer service. Our
          team is focused on continuous growth, from employee development to
          reinvesting in new gaming opportunities that enhance the player
          experience. <br />
          <br /> Our core values: delivering excellence, prioritizing customer
          satisfaction, and contributing positively to our communities.
        </p>
      </div>
      <div className="mission-vision">
        <div className="mission">
          <h4>Our Vision</h4>
          <p>
            To become the leading gaming company in Nigeria by 2026 and expand
            into three African countries by 2027, delivering world-class
            entertainment and gambling products through the most exciting and
            innovative channels.
          </p>
        </div>
        <div className="mission">
          <h4>Our Mission</h4>
          <p>
            Our mission is to deliver a world-class gaming experience by:
            Offering top-quality products; Creating fair and exciting odds;
            Providing exceptional customer service; Developing our team to serve
            you better; Building connections through impactful marketing;
            Supporting our local communities; and Continuously investing in new
            and exciting gaming opportunities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutComp;
