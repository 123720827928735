import React from "react";
import "./land-about.css";
import { Button, SvgCont } from "../comp-utils/CompUtils";
import { useNavigate } from "react-router-dom";

const LandAbout = () => {

  const navigate = useNavigate();

  const goToProduct = () => {
    navigate("/products")
  }
  const goToAbout = () => {
    navigate("/aboutus")
  }

  return (
    <SvgCont className={"landing-cont"}>
      <div className="hero-btn">
        <Button btnClass={"btn btn-hero"} btnText={"Go to games"} clickBtn={goToProduct} />
      </div>
      <div className="land-about_cont">
        <div className="land-about">
          <h3>What is Masterslive Network?</h3>
          <p>
          MastersLive Network is a premier gaming company offering sports betting, online, and physical casinos. Our goal is to lead Nigeria’s gaming industry and expand into more African countries in the nearest future.  With world-class products, innovative technology, and exceptional customer service, we aim to create thrilling, unforgettable experiences for all our players.
          </p>
        </div>
        <div className="land-about_btn">
          <Button btnClass={"btn-sec"} btnText={"Learn more"} clickBtn={(goToAbout)} />
        </div>
      </div>
    </SvgCont>
  );
};

export default LandAbout;
