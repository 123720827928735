import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import WebLayout from "./components/web-layout/WebLayout";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<WebLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contactus" element={<Contact />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
